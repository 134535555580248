<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        extension-height="10"
        color="grey darken-4"
        dark
      >
        <v-toolbar-title>Business Registration</v-toolbar-title>
      </v-toolbar>
      <v-list>
        <v-list-group
          :value="true"
          no-action
        >
          <v-list-tile slot="activator">
            <v-list-tile-title>SOP Pilihan 1 / Option 1 SOP:</v-list-tile-title>
          </v-list-tile>
          <v-list>
            <v-list-group
              v-for="item in option1"
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template #activator>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>

              <v-list-tile
                v-for="subItem in item.option1"
                :key="subItem.title"
                :href="subItem.link"
                target="_blank"
              >
                <v-list-tile-content>
                  <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-tile-action>
              </v-list-tile>
            </v-list-group>
          </v-list>
        </v-list-group>
        <v-list-group
          :value="true"
          no-action
        >
          <v-list-tile slot="activator">
            <v-list-tile-title>SOP Pilihan 2 / Option 2 SOP:</v-list-tile-title>
          </v-list-tile>
          <v-list>
            <v-list-group
              v-for="item in option2"
              :key="item.title"
              :prepend-icon="item.icon"
              no-action
            >
              <template #activator>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>

              <v-list-tile
                v-for="subItem in item.option2"
                :key="subItem.title"
                :href="subItem.link"
                target="_blank"
              >
                <v-list-tile-content>
                  <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                </v-list-tile-content>

                <v-list-tile-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-tile-action>
              </v-list-tile>
            </v-list-group>
          </v-list>
        </v-list-group>
      </v-list>
    </v-card>
  </panel>
</template>

<script>
import { createGetParams } from '@/rest'

const pageTitle = 'Business Registration'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Downloads', disabled: false, to: '/downloads',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      option1: [],
      option2: [],
      title: pageTitle,
    }
  },

  created: function () {
    const params = createGetParams({})
    this.$rest.get('getBusinessRegistration.php', params)
      .then((response) => {
        this.option1 = response.data.option1
        this.option2 = response.data.option2
      }).catch(error => {
        console.log(error)
      })
  },
}
</script>
